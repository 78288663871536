<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex justify-content-between">
        <h1 class="my-auto">FAQs</h1>
        <b-button
          v-if="$can('create', 'faq')"
          class="mt-1 float-right"
          variant="primary"
          @click="$router.push({ name: 'faq-create' })"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Ajouter une faq</span>
        </b-button>
      </div>
    </b-card>
    <div v-if="isLoading">
      <!-- <b-spinner /> -->
      <content-loader
        view-box="0 0 400 460"
        :speed="2"
        primary-color="#f3f3f3"
        secondary-color="#ecebeb"
      >
        <circle cx="29" cy="30" r="17" />
        <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
        <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
        <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
      </content-loader>
    </div>

    <div
      class="text-center"
      v-if="!isLoading && (!faqList || faqList.length === 0)"
    >
      Aucun résultat
    </div>
    <b-row
      v-if="!isLoading && faqList && faqList.length > 0"
      class="blog-list-wrapper"
    >
      <!-- <b-col md="12">
        <b-row> -->
      <b-col v-for="(faq, index) in faqList" :key="index" md="6">
        <b-card tag="article" no-body>
          <template #header>
            <div class="w-100">
              <b-button
                v-if="$can('delete', 'faq')"
                class="float-right"
                title="Supprimer ce compte"
                variant="danger"
                @click="showConfirmDeleteFaqModal(faq, index)"
              >
                <feather-icon icon="ArchiveIcon" />
                Supprimer
                <span class="text-nowrap" />
              </b-button>
            </div>
          </template>
          <b-link :to="{ name: 'faq-detail', params: { faqId: faq._id } }">
            <!-- <b-img
              v-if="faq.banniere && faq.banniere[0]"
              :src="faq.banniere[0].url"
              :alt="faq.titre"
              class="card-img-top"
              width="600"
              height="400"
            /> -->
            <FileWidget
              v-if="faq.banniere[0]"
              :key="index"
              :type="'image'"
              :file="faq.banniere[0]"
              :b-img-props="{
                class: 'card-img-top',
                width: '600',
                height: '400',
                alt: faq.titre,
              }"
            />

            <!-- <img
            v-if="blog.banniere"
            :src="blog.banniere.url"
            class="card-img-top"
          > -->
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'faq-detail', params: { faqId: faq._id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ faq.titre }}
              </b-link>
            </b-card-title>
            <!-- <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  href="javascript:void(0)"
                  size="24"
                  :src="blog.avatar"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link class="text-body">{{ blog.userFullName }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ blog.blogPosted }}</small>
              </b-media-body>
            </b-media> -->
            <div class="my-1 py-25">
              <b-link v-for="(tag, index) in faq.cibleCategorie" :key="index">
                <b-badge pill class="mr-75" :variant="tagsColor(tag)">
                  {{ tag }}
                </b-badge>
              </b-link>
            </div>
            <b-card-text class="blog-content-truncate">
              <!-- {{ blog.contenu }} -->
              <span v-html="faq.contenu" />
            </b-card-text>
            <hr />
            <div class="text-right">
              <!-- <b-link :to="{ path: `/pages/blog/${blog.id}#blogComment`}">
              <div class="d-flex align-items-center text-body">
                <feather-icon
                  icon="MessageSquareIcon"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ kFormatter(blog.comment) }} Comments</span>
              </div>
            </b-link> -->
              <b-link
                :to="{ name: 'faq-detail', params: { faqId: faq._id } }"
                class="font-weight-bold"
              >
                Voir plus
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- </b-row> -->
      <!-- </b-col> -->

      <b-col cols="12">
        <PaginationComponent
          :table-data="faqList"
          :per-page="paginationData.perPage"
          :total-rows="paginationData.total"
          @change="onPaginationChange"
        />
      </b-col>
    </b-row>

    <!-- Modal pour notifier l'archivage du compte -->
    <b-modal
      id="modal-1"
      :ref="confirmDeleteFaqModalRef"
      title="Confirmer suppression"
      ok-only
      ok-title=""
      ok-variant=""
      no-close-on-backdrop
    >
      <b-card-text>
        Êtes-vous sûre de vouloir supprimer cet élément ?
        <!-- {{utilisateurId}} -->
      </b-card-text>
      <template #modal-footer>
        <div class="">
          <b-button variant="danger" @click="deleteFaq">
            <b-spinner v-if="isDeletingFaq" small />
            Supprimer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BSpinner,
  BModal,
  BButton,
} from "bootstrap-vue";
import { ContentLoader } from "vue-content-loader";

import { mapActions } from "vuex";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import utilsService from "@/services/utils/utils.service";
import faqStoreModule from "@/store/faq";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

import PaginationComponent from "@/components/PaginationComponent.vue";
import FileWidget from "@/components/FileWidget.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
    BSpinner,
    ContentLoader,
    PaginationComponent,
    FileWidget,
    BModal,
  },

  setup() {
    const requiredStoreModules = [{ path: "faq", module: faqStoreModule }];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      confirmDeleteFaqModalRef: "",
      itemToDeleteId: {},
      isDeletingFaq: false,
      isLoading: false,
      search_query: "",
      faqList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: 140,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 20,
      },
      userRoleId: null,
      isSuperAdmin: null,
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        const {
          etablissementDetails,
          commissariatDetails,
          userData,
          userRoleId,
          isSuperAdmin,
        } = utilsService.currentUserUtils();
        this.userRoleId = userRoleId;
        this.isSuperAdmin = isSuperAdmin;

        await this.getListFaqs();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  // created() {
  //   this.$http.get('/blog/list/data').then(res => {
  //     this.faqList = res.data
  //   })
  //   this.$http.get('/blog/list/data/sidebar').then(res => {
  //     this.blogSidebar = res.data
  //   })
  // },

  methods: {
    ...mapActions("faq", {
      action_getListFaq: "getListFaq",
      action_deleteFaq: "deleteFaq",
    }),
    canCreateFAQ() {
      // return isSuperAdmin
      return this.$can("create", "faq");
    },

    getListFaqs() {
      this.isLoading = true;

      // console.log('⭕️', this.userRoleId)

      let params = {
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      };
      if (this.isSuperAdmin === false) {
        params.filter = {
          type: ["roles"],
          text: [this.userRoleId ? this.userRoleId : ""],
        };
      }

      // console.log(' params :::::: ', params)

      this.action_getListFaq(params)
        .then((response) => {
          console.log("response: ⭕️ ⭕️ ", response);
          const { data } = response;
          const { resources, total } = data;
          console.log("resources, total: ⭕️ ", resources, total);
          // filter by user role
          console.log("this.isSuperAdmin: ", this.isSuperAdmin);

          this.faqList = resources;
          // -------------------------------------------
          this.paginationData.total = total || res.data.resources.length || 0;
          if (this.paginationData.metaData.from == 0) {
            this.paginationData.metaData.from += 1;
          } else {
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage;
          }
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            total;
          // -------------------------------------------

          // if(this.isSuperAdmin === false){
          //   resources.forEach(faq => {
          //   // console.log('roles: ', faq.roles, faq.roles.length)
          //     let find = false
          //     faq.roles.map(role => {
          //       if(role._id === this.userRoleId ) find = true
          //     })
          //     if(!find && faq.roles.length === 0) find = true
          //     // console.log('this.userRoleId: ', this.userRoleId, find)

          //     if(find) this.faqList.push(faq)

          //   });

          //   console.log( '⭕️⭕️⭕️', this.faqList)
          //   // -------------------------------------------
          //   this.paginationData.total = this.faqList.length || res.data.resources.length || 0
          //   if (this.paginationData.metaData.from == 0) {
          //     this.paginationData.metaData.from += 1
          //   } else {
          //     this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
          //       - this.paginationData.perPage
          //   }
          //   this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
          //     - this.paginationData.perPage
          //     + this.faqList.length
          //   // -------------------------------------------

          // }else{
          //   this.faqList = resources
          //   // -------------------------------------------
          //   this.paginationData.total = total || res.data.resources.length || 0
          //   if (this.paginationData.metaData.from == 0) {
          //     this.paginationData.metaData.from += 1
          //   } else {
          //     this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
          //       - this.paginationData.perPage
          //   }
          //   this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
          //     - this.paginationData.perPage
          //     + total
          //   // -------------------------------------------
          // }

          this.isLoading = false;
          // console.log('faqList: ', this.faqList)
          // console.log('faq-list', response, this.tableData)
          // this.paginationData.total = response.data.total
          // this.isLoading = false

          // if (this.paginationData.metaData.from == 0) this.paginationData.metaData.from += 1
          // else this.paginationData.metaData.from = (this.paginationData.perPage * this.paginationData.currentPage) - (this.paginationData.perPage)
          // this.paginationData.metaData.to = (this.paginationData.perPage * this.paginationData.currentPage) - this.paginationData.perPage + this.tableData.length
        })
        .catch((err) => {
          this.isLoading = false;
          // console.log('error-faq-list', err)
        });
    },
    deleteFaq() {
      this.isDeletingFaq = true;
      const { id, tabId } = this.itemToDeleteId || {};
      this.action_deleteFaq(id)
        .then((response) => {
          console.log("response :>> ", response);

          // eslint-disable-next-line no-underscore-dangle
          this.faqList = this.faqList.filter((e, index) => index !== tabId);
          this.isDeletingFaq = false;
          this.hideConfirmDeleteFaqModal();
        })
        .catch((err) => {
          this.isDeletingFaq = false;
          // console.log('error-faq-list', err)
        });
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val };
      this.getListFaqs();
    },
    showConfirmDeleteFaqModal(toDelete, index) {
      const { _id: id } = toDelete || {};
      this.itemToDeleteId = { id, tabId: index };
      this.$refs[this.confirmDeleteFaqModalRef].show();
    },
    hideConfirmDeleteFaqModal() {
      this.itemToDeleteId = null;
      this.$refs[this.confirmDeleteFaqModalRef].hide();
    },

    kFormatter,
    tagsColor(tag) {
      if (tag === "Quote") return "light-info";
      if (tag === "Gaming") return "light-danger";
      if (tag === "Fashion") return "light-primary";
      if (tag === "Video") return "light-warning";
      if (tag === "Food") return "light-success";
      return "light-primary";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
